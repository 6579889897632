<template>
  <div>
     <v-toolbar flat>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" @click="createDevice()">
        Create Own SIP Server
      </v-btn>
      <!-- basic setting for adding device New -->
    </v-toolbar>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Create Own SIP Server</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="isFormValid">
          <v-card-text class="pb-0 mb-0">
            <v-text-field
              v-model="getSelectSoftSwitchData.server_name"
              label="Trunk Name"
              append-icon="person"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="getSelectSoftSwitchData.ip"
              :rules="ipSwitchRules"
              label="Ip"
              append-icon="person"
              outlined
            >
              <template v-slot:append>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="primary">mdi-information</v-icon>
                  </template>
                  <div style="max-width: 250px; white-space: normal; padding: 8px;">
                    By default, calls will be forwarded to SIP port 5060. If your server listens to a different SIP port, enter it here.
                    <br /><br />
                    <strong>Example:</strong> If your server IP address is <strong>192.168.11.1</strong> and it listens to SIP port <strong>5062</strong>,
                    type the address as <strong>192.168.11.1:5062</strong>.
                  </div>
                </v-tooltip>
              </template>
            </v-text-field>
            <v-select
                v-model="getSelectSoftSwitchData.inboundFormat"
                :items="inboundFormatOptions"
                item-text="text"
                item-value="value"
                label="Select Inbound Format"
                append-icon="person"
                outlined
            ></v-select>
            <!-- Conditionally show the text box when "username/Extension@Ip" is selected -->
            <v-text-field
                v-if="getSelectSoftSwitchData.inboundFormat === 'username'"
                v-model="getSelectSoftSwitchData.username"
                label="Enter Username or Extension"
                placeholder="Up to 15 alphanumeric"
                append-icon="person"
                outlined
                :rules="[usernameRule]"
                maxlength="15"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="info" text @click="close" elevation="0"> Cancel </v-btn>
            <v-btn
              color="primary"
              text
              elevation="0"
              :loading="submitLoader"
              @click="addConnectivityServersData()"
              :disabled="!isFormValid"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
 <!-- own server settings for edit device -->
    <v-dialog v-model="dialogEdit" max-width="400">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Own SIP Server</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="isFormValid">
        <v-card-text class="pb-0 mb-0">
          <v-text-field
            v-model="getSelectSoftSwitchData.server_name"
            label="Trunk Name"
            append-icon="person"
            outlined
            disabled
          ></v-text-field>
          <v-text-field
              v-model="getSelectSoftSwitchData.ip"
              :rules="ipSwitchRules"
              label="Ip"
              append-icon="person"
              outlined
            >
             <template v-slot:append>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="primary">mdi-information</v-icon>
                  </template>
                  <div style="max-width: 250px; white-space: normal; padding: 8px;">
                    By default, calls will be forwarded to SIP port 5060. If your server listens to a different SIP port, enter it here.
                    <br /><br />
                    <strong>Example:</strong> If your server IP address is <strong>192.168.11.1</strong> and it listens to SIP port <strong>5062</strong>,
                    type the address as <strong>192.168.11.1:5062</strong>.
                  </div>
                </v-tooltip>
              </template>
            </v-text-field>
          <v-select
              v-model="getSelectSoftSwitchData.inboundFormat"
              :items="inboundFormatOptions"
              item-text="text"
              item-value="value"
              label="Select Inbound Format"
              append-icon="person"
              outlined
              disabled
          ></v-select>
          <!-- Conditionally show the text box when "username/Extension@Ip" is selected -->
          <v-text-field
              v-if="getSelectSoftSwitchData.inboundFormat === 'username'"
              v-model="getSelectSoftSwitchData.username"
              label="Enter Username or Extension"
              placeholder="Up to 15 alphanumeric"
              append-icon="person"
              :rules="[usernameRule]"
              maxlength="15"
              outlined
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEdit"> Cancel </v-btn>
           <v-btn
            color="primary"
            text
            elevation="0"
            :loading="submitLoader"
            @click="editServerData()"
            :disabled="!isFormValid"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="600">
      <v-card>
        <v-card-title>
          <span class="error--text"> Warning: </span>
          <!-- <span class="pt-2"> -->
          This action will delete device from our server. 
          Any DID linked to this Device will no more work.
          <!-- </span> -->
        </v-card-title>

        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn color="error" class="px-8" outlined @click="closeDelete"
            >No</v-btn
          >
          <v-btn color="green" outlined class="px-8" @click="deleteItemConfirm"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card elevation="1">
      <v-card-actions class="py-1">
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-data-table
        :headers="headers"
        :items="serverListArray"
        :loading="tableLoader"
      >
        <template v-slot:[`item.indexNo`]="{ item }">
          {{ serverListArray.indexOf(item) + 1 }}
        </template>
        <template v-slot:[`item.server_name`]="{ item }">
          {{ item.server_name }}
        </template>
        <template v-slot:[`item.ipAddress`]="{ item }">
          {{ item.ipAddress }}
        </template>
        <template v-slot:[`item.trunkTypeNumber`]="{ item }">
          {{ item.trunkTypeText }} <!-- Display the text instead of raw value -->
        </template>
        <template v-slot:[`item.viewAction`]="{ item }">
            <v-btn x-small outlined fab @click="editItem(item)" color="teal">
              <v-icon small> mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              @click="deleteItem(item)"
              fab
              x-small
              color="error"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
        </template>
      </v-data-table>
      <loading :active="isLoading" :loader="loader" />
    </v-card>
  </div>
</template>

<script>
import numberManagerAPI from "@/services/numberManagerAPI.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      loader: "bars",
      headers: [
        { text: "#", value: "indexNo", sortable: false },
        { text: "Trunk Name", value: "server_name" },
        { text: "IP Address", value: "ipAddress" },
        { text: "Trunk Type", value: "trunkTypeText" },  // Use trunkTypeText
        { text: "Actions", value: "viewAction"},
      ],
      // Validation rule for username/extension field
      usernameRule: v => /^[a-zA-Z0-9]{1,15}$/.test(v) || 'Up to 15 alphanumeric characters only',
      submitLoader: false,
      serverListArray: [],
      tableLoader: false,
      getSelectSoftSwitchData: {
        inboundFormat: '',
        username: '' ,
        isAssignedTrunk:'N',
        inboundFormat: '',
      },
      inboundFormatOptions: [
        { text: "DID@IP", value: "strip_plus" },
        { text: "+DID@IP", value: "e164" },
        { text: "username/Extension@IP", value: "username" }
      ],
      dialog: false,
      dialogEdit: false,
      dialogDelete: false,
      isFormValid: false,
      server_name: '',
      ipSwitchRules: [
        // (v) =>
        //   /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
          // Check for forbidden characters (@, /, \)
        (value) => {
          const forbiddenChars = ["@", "/", "\\"]; // Characters to disallow

          if (value && forbiddenChars.some((char) => value.includes(char))) {
            return "Please enter a valid text without @, /, or \\ characters.";
          }
          return true; // Validation passed
        },
        // Ensure value is not empty
        value => !!value || 'IP Address is required', // Required rule
        value => this.isValidIP(value) || 'Invalid IP address' // IP validation rule
      
      ],
    };
  },
   watch: {
    dialog(val) {
      val || this.close();
    },
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getServerList();
      },
      deep: true,
    },
  },

  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.getServerList();
  },
  methods: {
    createDevice() {
      this.dialog = true;
      // Reset the form data
      this.getSelectSoftSwitchData = {
        server_name: '',
        ip: '',
        inboundFormat: '',
        username: '',
        isAssignedTrunk: 'N'
      };
      // this.AddDeviceDataJson.device_type = "softphone";
    },
    //  isValidIP(ip) {
    //   const ipPattern = /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}$/;
    //   return ipPattern.test(ip);
    // },

    isValidIP(ip) {
      const ipPattern = /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}(:([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$/;
      return ipPattern.test(ip);
    },

    setValue: function () {
      this.getSelectSoftSwitchData = {};
      this.softSwitchData = {};
    },
    closeAssignNumberModal() {
      this.getSelectSoftSwitchData = {};
      this.softSwitchData = {};
      this.$emit("close-asign-modal");
    },
    async getServerList() {
      let serverList = [];
      this.serverListArray = [];
      this.isLoading = true;
      this.tableLoader = true;

      this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
      const deviceDataJson = {
        accountId: this.userDetail.accountId,
        forwardType: "SIP_URI",
      };

      try {
        let response = await numberManagerAPI.getFilteredDeviceList(deviceDataJson);
        console.log(response);

        if (response.serverList) {
          for (let element of response.serverList) {
            let username = '';
            let ipAddress = '';

            if (element.auth && element.auth.ip) {
              const fullValue = element.auth.ip; // Example: "abc@12.13.12.13"
              // Check if fullValue contains '@'
              if (fullValue.includes('@')) {
                  // If it contains '@', split into username and ipAddress
                  username = fullValue.split('@')[0];
                  ipAddress = fullValue.split('@')[1];
              } else {
                  // If it doesn't contain '@', set fullValue as ipAddress
                  ipAddress = fullValue;
              }
            }

            // Get the trunk type text by matching the inbound_format with options
            const trunkTypeText = this.inboundFormatOptions.find(option => option.value === element.options.inbound_format)?.text || "Unknown";
            serverList.push({
              server_name: element.server_name,
              ipAddress: ipAddress,
              trunkTypeText: trunkTypeText,  // Use the mapped trunk type text
              username: username,
              inboundFormat: element.options.inbound_format,
            });
          }

          this.serverListArray = serverList; // Assign data to the table
        }
      } catch (error) {
        console.error("API call error: ", error);
      } finally {
        this.isLoading = false;
        this.tableLoader = false;
      }
    },
    async addConnectivityServersData() {
      if (
        this.getSelectSoftSwitchData.server_name &&
        this.getSelectSoftSwitchData.inboundFormat &&
        this.getSelectSoftSwitchData.ip
      ) {
        this.isLoading = true;
        this.submitLoader = true;
        var didToServerObj = {
          accountId: this.userDetail.accountId,
          authToken: this.userDetail.authToken,
          ip: this.getSelectSoftSwitchData.ip,
          serverName: this.getSelectSoftSwitchData.server_name,
          inboundFormat: this.getSelectSoftSwitchData.inboundFormat,
          username: this.getSelectSoftSwitchData.username,
          isAssignedTrunk: "N",
        };
        try {
          let response = await numberManagerAPI.addConnectivityServers(
            didToServerObj
          );
          if(response && response.responseData.status && response.responseData.status === 'error'){
            this.isLoading = false;
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "something wrong!",
              color: "error",
            });
          }else {
            this.$root.$emit("SHOW_SNACKBAR", {
              text: response.responseMessage,
              color: "success",
            });
            this.getServerList();
            this.close();
          }
          // this.$emit("closedAssignedModal");
          // this.closeAssignNumberModal();
          this.submitLoader = false;
          this.isLoading = false;
        } catch (error) {
          this.submitLoader = false;
          this.isLoading = false;
          console.log("====error===", error);
          if(error.data && error.data.messageDetail){
            this.$root.$emit("SHOW_SNACKBAR", {
              text: error.data.messageDetail,
              color: "error",
            });
          }else {
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "something wrong!",
              color: "error",
            });
          }
        }
      } else {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Fill the fields!",
          color: "error",
        });
      }
    },
    async editItem(item) {
      this.isLoading = true;
      this.itemIDSent = item.id;
      // Set the values in getSelectSoftSwitchData
      this.getSelectSoftSwitchData.server_name = item.server_name;
      this.getSelectSoftSwitchData.ip = item.ipAddress;
      this.getSelectSoftSwitchData.inboundFormat = item.inboundFormat;
      this.getSelectSoftSwitchData.username = item.username;
      this.isLoading = false;
      this.dialogEdit = true;
    },

     async editServerData() {
       if (
        this.getSelectSoftSwitchData.server_name &&
        this.getSelectSoftSwitchData.inboundFormat &&
        this.getSelectSoftSwitchData.ip
      ) {
        this.isLoading = true;
        var didToServerObj = {
          accountId: this.userDetail.accountId,
          authToken: this.userDetail.authToken,
          ip: this.getSelectSoftSwitchData.ip,
          serverName: this.getSelectSoftSwitchData.server_name,
          inboundFormat: this.getSelectSoftSwitchData.inboundFormat,
          username: this.getSelectSoftSwitchData.username,
          isAssignedTrunk: "N",
        };
      
        try {
          let response = await numberManagerAPI.updateConnectivityServers(didToServerObj);          
            setTimeout(() => {
            this.$root.$emit("SHOW_SNACKBAR", {
              text: response.messageDetail,
              color: "success",
            });
          }, 0);
          this.getServerList();
          this.dialogEdit = false;
          this.isLoading = false;
        } catch (error) {
          if(error.data && error.data.messageDetail){
            this.$root.$emit("SHOW_SNACKBAR", {
              text: error.data.messageDetail,
              color: "error",
            });
          }
          this.isLoading = false;
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
        this.isLoading = false;
        setTimeout(() => {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Trunk Name is required.",
            color: "error",
          });
        }, 1000);
      }
    },

    async deleteItemConfirm() {
      this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
      var didToServerObj = {
        accountId: this.userDetail.accountId,
        authToken: this.userDetail.authToken,
        ip: this.getSelectSoftSwitchData.ip,
        serverName: this.getSelectSoftSwitchData.server_name,
        inboundFormat: this.getSelectSoftSwitchData.inboundFormat,
        username: this.getSelectSoftSwitchData.username,
        isAssignedTrunk: "N",
      };
      this.isLoading = true;
      try {
        let response = await numberManagerAPI.deleteConnectivityServers(didToServerObj);          
        if (response.responseMessage == "success") {
          this.dialogDelete = false;
          setTimeout(() => {
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "PBX Server Deleted Sucessfully.",
              color: "success",
            });
          }, 0);
          this.getServerList();
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },

    close() {
      this.dialog = false;
      // Reset the form data when closing the dialog
      this.getSelectSoftSwitchData = {
        server_name: '',
        ip: '',
        inboundFormat: '',
        username: '',
        isAssignedTrunk: 'N'
      };
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeEdit() {
      this.dialogEdit = false;
    },
    async deleteItem(item) {
      this.dialogDelete = true;
      this.itemIDSent = item.id;
      // Set the values in getSelectSoftSwitchData
      this.getSelectSoftSwitchData.server_name = item.server_name;
      this.getSelectSoftSwitchData.ip = item.ipAddress;
      this.getSelectSoftSwitchData.inboundFormat = item.inboundFormat;
      this.getSelectSoftSwitchData.username = item.username;
    },
  },
};
</script>

<style scoped>
.text-capitalize {
  text-transform: capitalize;
}
</style>
